<template>
  <AbstractFilter
    :on-filter="filterJokes"
    :on-filter-reset="resetFilter"
    create-new-route-name="joke_new"
    filter-panel-class="filter-panel-joke"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('joke.filter.title')"
    />
    <Input
      v-model="filter.bodyText"
      id="filter_bodyText"
      :label="$t('joke.filter.bodytext')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('joke.filter.id')"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import JokeFilter from '@/model/JokeFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'

export default {
  name: 'JokeFilter',
  data () {
    return {
      filter: this._.cloneDeep(JokeFilter)
    }
  },
  components: {
    AbstractFilter,
    Input
  },
  methods: {
    filterJokes () {
      this.$store.commit('joke/setPage', 1)
      this.$store.commit('joke/setFilter', this.filter)
      this.$store.dispatch('joke/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(JokeFilter)
      this.$store.commit('joke/setFilter', this.filter)
      this.$store.dispatch('joke/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['joke/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-joke {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
